import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import { PageLayout, PageBreadcrumb } from '../../components'
import { t } from '../../lib/locale'

const Image = ({ src, alt }) => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: allFile(filter: {relativeDirectory: {eq: "about"}}) {
        edges {
          node {
            childImageSharp {
              fluid(maxWidth: 700) {
                originalName
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `)

  const file = data.placeholderImage.edges.find(({ node }) => node.childImageSharp.fluid.originalName === src)
  if (file) {
    return <Img fluid={file.node.childImageSharp.fluid} />
  }
  return ''
}

const ContentAboutTemplate = ({ pageContext }) => {
  const { lang, page } = pageContext

  const renderEN = (
    <>
      <Image src="about.jpg" className="img-fluid w-100" alt="Welcome at Up North, so nice to see you here!" />

      <h2 className="mt-5">Welcome at Up North, so nice to see you here!</h2>
      <h3>Who are we?</h3>
      <p>It's a bit cliché, but Up North is a Belgian online shop created from a passion for Scandinavian interior. Well, passion but also frustration because in the Benelux a number of super cool Scandinavian brands are often  still untraceable (or are, but give us some time;)). So then you can start doing some magic on the internet with shipments to other dispatch centres, or you can start a webshop by yourself. And that's exactly what we did. Not that we didn't give the dispatch centres a chance, but we got tired of the shipping costs being more expensive than the products themselves :)</p>
      <h3>Our goal?</h3>
      <p>We want to bring unique and fun Scandinavian interior products closer to you. That's why our focus is on more exclusive products and brands that you won't just find anywhere. Of course we also offer some well-known brands from the North, but we also try to put together a more unique assortment. For the Scandinavian beginners we put together nice, affordable and especially bite-sized packages that contain the most popular products. Because it is simply not so easy to turn your house into a Scandinavian paradise, and because choosing aa gift as an exclusive item is often not so easy.</p>
      <h3>What's importat to us?</h3>
      <p>Personality is essential to us, both for interior design and in the relationship with our customers.</p>
      <p>After all, an interior is where you live in every day, and where you really need to feel at home. We often find it such a shame that people get their entire interior from one store. No harm of course if it's of little importance to you, but we personally would miss some personality in our interior. At Up North we try to put you on your way to create your own personal dream interior. How? We try to give as many tips as possible in the description of our products. And gradually more and more interior advice will be published on our webshop. On the other hand, we do not compete with our colleagues; The more choice, the more creative you can be! ;)</p>
      <p>We also want to be very close to our customers. That's why we offer a personal packaging service. Are you telling us that you are buying a present for the birthday of your best friend Ben? Then we make sure that his name is on the birthday present, and we even add a personal message from you in the package. That's really awesome ?! Even if you have questions or feedback, you are always welcome with us. Not an online factory, rather a small but personal and cozy webshop!</p>
      <p>Have fun!&nbsp;</p>
    </>
  )

  const renderNL = (
    <>
      <Image src="about.jpg" className="img-fluid w-100" alt="Welkom bij Up North, leuk dat je er bent!" />

      <h2 className="mt-5">Welkom bij Up North, leuk dat je er bent!</h2>
      <h3>Als je je weg naar hier gevonden hebt, heb jij vast ook een zwak voor Scandinavisch design?</h3>
      <p>Omdat onze liefde voor Scandinavië zo groot is – en de keuze te klein was – besloten we om het noorden naar België te brengen. Zo kunnen we al dat moois met jullie delen. Online en in onze winkel.</p>
      <h3>Dit vinden we belangrijk.</h3>
      <p>Bij Up North willen we je voorzien van een fraai interieur en de leukste cadeaus. Dat we best kieskeurig zijn, zien we als een compliment. We willen dan ook <b>exclusievere items</b> aanbieden die je niet op elke hoek van de straat vindt. Ook bij de gekendere Scandinavische merken selecteren we telkens een <b>verrassend assortiment</b>. Verwacht je aan originele producten met een minimalistische feel waar steeds een sterke visie achter zit. Duurzame producten hebben bij ons steeds een streepje voor.</p>
      <p>Naast een juiste <b>prijs-kwaliteitverhouding</b> vinden we <b>persoonlijkheid</b> een essentiële factor in onze manier van ondernemen. Zowel qua interieur – een reflectie van jouw identiteit – als qua klantenservice.</p>
      <p>We staan je graag bij met <b>advies op maat</b>. Bij Up North begeleiden we je met de nodige tips &amp; tricks naar het creëren van jouw droominterieur. Want je eigen ‘thuis’ creëren, dat kan alleen jij.</p>
      <h3>Waarom we doen wat we doen.</h3>
      <p>Een glimlach op iemands gezicht toveren, daar doen we het voor. Zowel achter je smartphone als in onze winkel, in je nieuwe zetel of bij het afgeven van een pakje (jammer dat we daar niet altijd bij kunnen zijn!). Met onze <b>persoonlijke inpakservice</b> maken we dat afgeefmoment nog specialer. Laat je ons weten dat je een geschenk koopt voor je beste vriendin Fien? Dan zorgen wij voor een mooi, ingepakt cadeautje met een persoonlijke boodschap. Geen online fabriekje dus, wel een gezellige go-to voor jouw cadeaus en interieuritems.</p>
      <p>Heb je vragen? Hulp nodig? Dan weet je ons te vinden!;)</p>
      <p>Veel shopplezier,<br />Valerie en Stefan</p>
      <small>Voor fans van: HAY, Ferm Living, Muuto, Liewood, Konges Slojd ...</small>
    </>
  )

  return (
    <PageLayout className="shopping-bag" lang={lang} switcher={page}>
      <PageBreadcrumb current={page} items={page.breadcrumbs} lang={lang} />
      <h1>{t(page, 'title', lang)}</h1>
      {+lang.id_lang === 1 && renderEN}
      {+lang.id_lang === 4 && renderNL}
    </PageLayout>
  )
}

export default ContentAboutTemplate
